import { T } from '@tolgee/react'

class AIController {
  supportedTextModels = [
    {
      value: 'gpt-4o-mini',
      label: 'GPT-4o mini',
      supportsImages: true,
      supportsStreaming: true,
      supportsWWW: true,
      isGuru: false,
      isDefault: true,
      description: (
        <T keyName={'eleo-chat-settings-model-description-gpt4o-mini'}>
          Best speed and good results
        </T>
      ),
    },
    {
      value: 'claude-sonnet',
      label: 'Claude Sonnet 3.5',
      supportsImages: true,
      supportsWWW: true,
      supportsStreaming: true,
      isGuru: true,
      isDefault: false,
      description: (
        <T keyName={'eleo-chat-settings-model-description-sonnet'}>
          Most sophisticated model by Anthropic. Words count x10
        </T>
      ),
    },
    {
      value: 'gpt-4o',
      label: 'GPT-4o',
      supportsImages: true,
      supportsWWW: true,
      supportsStreaming: true,
      isGuru: true,
      isDefault: false,
      description: (
        <T keyName={'eleo-chat-settings-model-description-gpt4o'}>
          Most sophisticated model by OpenAI. Words count x10
        </T>
      ),
    },
    {
      value: 'gpt-o1',
      label: 'GPT-o1',
      supportsImages: false,
      supportsWWW: false,
      supportsStreaming: false,
      isGuru: true,
      isDefault: false,
      description: (
        <T keyName={'eleo-chat-settings-model-description-gpto1'}>
          New reasoning model by OpenAI. Words count x10
        </T>
      ),
      experimental: true,
      availableFrom: 'Pro',
    },
    {
      value: 'gpt-o1-mini',
      label: 'GPT-o1-mini',
      supportsImages: false,
      supportsStreaming: false,
      supportsWWW: false,
      isGuru: true,
      isDefault: false,
      description: (
        <T keyName={'eleo-chat-settings-model-description-gpto1-mini'}>
          Fast reasoning model by OpenAI. Words count x10
        </T>
      ),
      experimental: true,
      availableFrom: 'Standard',
    },
    {
      value: 'gemini-2.0-flash',
      label: 'Gemini Flash',
      supportsImages: true,
      supportsStreaming: true,
      supportsWWW: true,
      isGuru: true,
      isDefault: false,
      description: (
        <T keyName={'eleo-chat-settings-model-description-gemini-1.5-flash'}>
          Fastest reasoning model by Google.
        </T>
      ),
    },
    {
      value: 'gemini-2.0-flash-lite',
      label: 'Gemini Flash Lite',
      supportsImages: true,
      supportsStreaming: true,
      supportsWWW: true,
      isGuru: true,
      isDefault: false,
      description: (
        <T keyName={'eleo-chat-settings-model-description-gemini-1.5-flash'}>
          Fastest reasoning model by Google.
        </T>
      ),
    },
    {
      value: 'gemini-1.5-pro',
      label: 'Gemini 1.5 Pro',
      supportsImages: true,
      supportsStreaming: true,
      supportsWWW: true,
      isGuru: true,
      isDefault: false,
      description: (
        <T keyName={'eleo-chat-settings-model-description-gemini-1.5-pro'}>
          Best reasoning model by Google. Words count x10
        </T>
      ),
    },
  ]

  constructor() {
    if (!AIController.instance) {
      AIController.instance = this
    }

    return AIController.instance
  }

  getSupportedTextModels(includeExperimental = false) {
    let models = this.supportedTextModels
    if (!includeExperimental) models = models.filter((model) => !model.experimental)
    return models
  }

  getDefaultTextModel() {
    return this.supportedTextModels.find((model) => model.isDefault)
  }

  getTextModel(id) {
    return this.supportedTextModels.find((model) => model.value === id)
  }
}

const aiController = new AIController()

export default aiController
